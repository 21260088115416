.skills {
  background-color: white;
  color: black;
  padding: 25px 0px;
}

.skills h2 {
  font-size: 60px;
  text-align: center;
  background-color: black;
  color: white;
  display: inline-block;
  padding: 10px;
  margin-bottom: 50px;
}

.skillList {
  text-align: right;
  width: 290px;
  margin: auto;
  padding-right: 15px;
  border-right: 3px solid black;
}

.skillItem p:first-of-type {
  margin: 0 20px;
}

.skillItem p:last-of-type {
  text-align: center;
}
