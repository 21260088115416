.about {
  padding: 25px 0px;
}

.about h2 {
  font-size: 60px;
  text-align: center;
  background-color: black;
  color: white;
  display: inline-block;
  padding: 10px;
  margin-bottom: 50px;
}

.personalInfo {
  text-align: justify;
  width: 710px;
  margin: auto;
  padding-left: 15px;
  border-left: 3px solid black;
}

.personalInfo p {
  padding: 10px 0px;
}

span.highlights {
  font-size: 28px;
  font-style: italic;
}

@media only screen and (max-width: 992px) {
  .personalInfo {
    width: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .personalInfo {
    width: 400px;
  }
}

@media only screen and (max-width: 576px) {
  .personalInfo {
    width: 300px;
    padding-left: 10px;
  }

  span.highlights {
    font-size: 20px;
    font-weight: 800;
  }
}
