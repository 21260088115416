.presentation {
  background-color: black;
  color: white;
  height: 100vh;
  /* height: calc(var(--vh, 1vh) * 100); */
  /* min-height: 630px; */
  margin: 0;
  padding: 0;
  position: relative;
}

#myInfo {
  border-left: 4px solid white;
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translateY(-50%);
  padding-left: 15px;
  margin: auto;
  max-height: 250px;
  height: 0;
  overflow-y: hidden;
  z-index: 1;
}

.show {
  height: 250px !important;
  transition: height 0.4s ease-in-out;
}

#name,
#position,
#location {
  height: 0;
  overflow: hidden;
}

.showName {
  height: 135px !important;
  transition: height 0.7s ease-in-out;
  transition-delay: 0.5s;
}

.showPosition {
  height: 60px !important;
  transition: height 0.7s ease-in-out;
  transition-delay: 1s;
}

.showLocation {
  height: 40px !important;
  transition: height 0.7s ease-in-out;
  transition-delay: 1.2s;
}

#myInfo h1 {
  font-size: 100px;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}

#myInfo h2 {
  font-size: 40px;
  color: lightgray;
  margin: 0;
  padding: 0;
  padding-left: 5px;
}

#myInfo h3 {
  font-size: 30px;
  color: lightgray;
  margin: 0;
  padding: 0;
}

.presentation img {
  /* min-height: 230px; */
  /* min-height: 550px;
  max-height: calc(100vh - 100px);
  max-width: 50vw; */
  position: absolute;
  bottom: 0px;
  filter: grayscale(60%) !important;
  animation-name: imageAnimation;
  animation-duration: 2s;
  position: absolute;
  left: 0px;
  overflow: hidden;
  /* height: 1000px;
  width: 1000px; */
}

@keyframes imageAnimation {
  from {
    left: -50px;
  }
  to {
    left: 0px;
  }
}

/* -------------- Landscape --------------- */

@media (orientation: landscape) {
  .presentation img {
    max-height: calc(100vh - 100px);
    max-width: 50vw;
  }

  @media only screen and (max-width: 1400px) {
    #myInfo {
      border-left: 3px solid white;
      max-height: 215px;
    }

    #myInfo h1 {
      font-size: 90px !important;
    }

    #myInfo h2 {
      font-size: 35px !important;
    }

    #myInfo h3 {
      font-size: 32px !important;
    }

    .show {
      transition: height 0.6s ease-in-out;
    }

    .showName {
      height: 115px !important;
    }

    .showPosition {
      height: 45px !important;
    }

    .showLocation {
      height: 60px !important;
    }

    #locationIcon {
      font-size: xx-large !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    #myInfo {
      /* border-left: 3px solid white; */
      max-height: 180px;
    }

    #myInfo h1 {
      font-size: 65px !important;
    }

    #myInfo h2 {
      font-size: 30px !important;
    }

    #myInfo h3 {
      font-size: 27px !important;
    }

    .show {
      transition: height 0.6s ease-in-out;
    }

    .showName {
      height: 95px !important;
    }

    .showPosition {
      height: 40px !important;
    }

    .showLocation {
      height: 60px !important;
    }

    #locationIcon {
      font-size: xx-large !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    .presentation {
      height: calc(100vh - 50px);
    }
  }

  @media only screen and (max-width: 992px) {
    #myInfo {
      /* border-left: 3px solid white; */
      max-height: 160px;
    }

    #myInfo h1 {
      font-size: 55px !important;
    }

    #myInfo h2 {
      font-size: 25px !important;
    }

    #myInfo h3 {
      font-size: 22px !important;
    }

    .show {
      transition: height 0.6s ease-in-out;
    }

    .showName {
      height: 80px !important;
    }

    .showPosition {
      height: 35px !important;
    }

    .showLocation {
      height: 60px !important;
    }

    #locationIcon {
      font-size: xx-large !important;
    }
  }

  @media only screen and (max-width: 768px) {
    #myInfo {
      /* border-left: 3px solid white; */
      max-height: 125px;
    }

    #myInfo h1 {
      font-size: 40px !important;
    }

    #myInfo h2 {
      font-size: 18px !important;
    }

    #myInfo h3 {
      font-size: 16px !important;
    }

    .show {
      transition: height 0.6s ease-in-out;
    }

    .showName {
      height: 62px !important;
    }

    .showPosition {
      height: 25px !important;
    }

    .showLocation {
      height: 60px !important;
    }

    #locationIcon {
      font-size: xx-large !important;
    }
  }

  @media only screen and (max-width: 576px) {
    #myInfo {
      border-left: 2px solid white;
      max-height: 115px;
      padding-left: 8px;
    }

    #myInfo h1 {
      font-size: 35px !important;
    }

    #myInfo h2 {
      font-size: 17px !important;
    }

    #myInfo h3 {
      font-size: 15px !important;
    }

    .show {
      transition: height 0.6s ease-in-out;
    }

    .showName {
      height: 55px !important;
    }

    .showPosition {
      height: 25px !important;
    }

    .showLocation {
      height: 60px !important;
    }

    #locationIcon {
      font-size: xx-large !important;
    }
  }
}

/* -------------- Portrait --------------- */

@media (orientation: portrait) {
  .presentation img {
    max-height: 50vh;
    max-width: 100vw;
  }

  #myInfo {
    border-left: 3px solid white;
    top: 20%;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    width: 610px;
    padding-left: 10px;
    /* max-height: 215px; */
    max-height: 50%;
  }

  #myInfo h1 {
    font-size: 90px !important;
  }

  #myInfo h2 {
    font-size: 35px !important;
  }

  #myInfo h3 {
    font-size: 32px !important;
  }

  .show {
    transition: height 0.6s ease-in-out;
  }

  .showName {
    height: 115px !important;
  }

  .showPosition {
    height: 45px !important;
  }

  .showLocation {
    height: 60px !important;
  }

  #locationIcon {
    font-size: xx-large !important;
  }

  @media only screen and (max-width: 768px) {
    @media only screen and (max-height: 899px) {
      .presentation {
        height: calc(100vh - 100px);
      }

      #myInfo {
        border-left: 3px solid white;
        top: 90px;
        left: 50%;
        transform: translateX(-50%) translateY(0%);
        width: 308px;
        padding-left: 10px;
        max-height: 140px;
      }

      #myInfo h1 {
        font-size: 50px !important;
      }

      #myInfo h2 {
        font-size: 25px !important;
      }

      #myInfo h3 {
        font-size: 22px !important;
      }

      .show {
        transition: height 0.6s ease-in-out;
      }

      .showName {
        height: 70px !important;
      }

      .showPosition {
        height: 35px !important;
      }

      .showLocation {
        height: 60px !important;
      }

      #locationIcon {
        font-size: x-large !important;
      }
    }

    @media only screen and (min-height: 900px) {
      .presentation {
        height: calc(100vh - 100px);
      }

      #myInfo {
        border-left: 3px solid white;
        top: 120px;
        left: 50%;
        transform: translateX(-50%) translateY(0%);
        width: 456px;
        padding-left: 10px;
        max-height: 190px;
      }

      #myInfo h1 {
        font-size: 75px !important;
      }

      #myInfo h2 {
        font-size: 35px !important;
      }

      #myInfo h3 {
        font-size: 30px !important;
      }

      .show {
        transition: height 0.6s ease-in-out;
      }

      .showName {
        height: 95px !important;
      }

      .showPosition {
        height: 45px !important;
      }

      .showLocation {
        height: 60px !important;
      }

      #locationIcon {
        font-size: x-large !important;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    #myInfo {
      border-left: 2px solid white;
      width: 280px;
      max-height: 130px;
      top: 80px;
    }

    #myInfo h1 {
      font-size: 45px !important;
    }

    #myInfo h2 {
      font-size: 20px !important;
    }

    #myInfo h3 {
      font-size: 18px !important;
    }

    .showName {
      height: 65px !important;
    }

    .showPosition {
      height: 30px !important;
    }

    .showLocation {
      height: 40px !important;
    }
  }

  @media only screen and (max-width: 374px) {
    #myInfo {
      /* border-left: 2px solid white; */
      width: 219px;
      max-height: 110px;
      top: 60px;
      padding-top: 5px;
    }

    #myInfo h1 {
      font-size: 35px !important;
    }

    #myInfo h2 {
      font-size: 17px !important;
    }

    #myInfo h3 {
      font-size: 15px !important;
    }

    .showName {
      height: 50px !important;
    }

    .showPosition {
      height: 25px !important;
    }

    .showLocation {
      height: 40px !important;
    }
  }
}
