.projectItem {
  margin: 25px 0;
}

.projectInfoBox {
  margin-top: -110px;
  padding: 130px 50px 25px 50px;
  padding-bottom: 25px;
  background-color: rgb(243, 245, 247);
  width: 1300px;
  max-width: 90vw;
  border-radius: 60px;
}

.projectIcon {
  height: 50px !important;
  width: 50px !important;
}

.webIcon {
  margin-left: 5px;
  margin-right: 20px;
  height: 50px !important;
  width: 50px !important;
}

.gitHubIcon {
  margin-left: 20px;
  margin-right: 5px;
  height: 42px !important;
  width: 42px !important;
}

.projectItem h3 {
  font-size: 40px;
}

.projectItem h4 {
  display: inline-block;
  padding: 10px;
  background-color: black;
  color: white;
  left: 50%;
  position: absolute;
  font-size: 20px;
}

.projectItem p {
  text-align: left;
}

.projectItem p:first-of-type {
  padding-top: 30px;
}

.projectItem p:last-of-type {
  padding-top: 15px;
  font-weight: bold;
}

@media only screen and (max-width: 1199px) {
  .projectInfoBox {
    margin-top: -100px;
    padding: 120px 50px 25px 50px;
  }
}

@media only screen and (max-width: 991px) {
  .projectInfoBox {
    margin-top: -80px;
    padding: 100px 50px 25px 50px;
  }
  .projectItem p:first-of-type {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .projectInfoBox {
    margin-top: -60px;
    padding: 80px 30px 15px 30px;
    border-radius: 40px;
  }

  .webIcon {
    height: 40px !important;
    width: 40px !important;
  }

  .gitHubIcon {
    height: 35px !important;
    width: 35px !important;
  }
}

@media only screen and (max-width: 575px) {
  .projectItem h4 {
    left: 0%;
    position: relative;
  }

  .projectInfoBox {
    margin-top: -80px;
    padding: 130px 20px 5px 20px;
    border-radius: 20px;
  }

  .projectIcon {
    margin: 0px 30px;
  }
}
