.header {
  background-color: black;
  height: 70px;
  position: fixed;
  width: 100vw;
  z-index: 2;
}

#headerOptions {
  display: block;
  position: absolute;
  width: 100vw !important;
  animation-name: headerAnimation;
  animation-duration: 2s;
  padding: 0 15px;
  bottom: 14px;
}

@keyframes headerAnimation {
  from {
    bottom: 0px;
  }
  to {
    bottom: 14px;
  }
}

.header h1,
.header p {
  color: white;
  margin: 0;
}

.header h1 {
  font-size: 35px;
}

.headerOptions p {
  margin-left: 40px;
}

.afterParagraph {
  position: relative;
}

.afterParagraph::after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  bottom: -10px;
  background: white;
  height: 4px;
  transition: width 0.5s ease;
}

.afterParagraph:hover::after,
.afterParagraph:focus::after,
.afterParagraph:active::after {
  left: 0;
  right: auto;
  width: 100%;
}

.headerOptionsItem {
  width: 0;
}

#fullScreenMenu {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  overflow-x: hidden;
  transition: 0.5s;
}

.showFullScreenMenu {
  width: 100vw !important;
}

#fullScreenMenuOptions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#fullScreenMenuOptions p {
  font-size: 50px;
  color: white;
  transition: 0.5s;
  text-decoration: none;
}

#fullScreenMenuOptions p:hover {
  text-decoration: none !important;
  color: white;
}

#closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: white;
  cursor: pointer;
}

@media (orientation: landscape) {
  @media only screen and (max-width: 991px) {
    .header {
      height: 55px;
    }

    #headerOptions h1 {
      font-size: 20px;
    }
  }

  @media only screen and (max-height: 576px) {
    #fullScreenMenuOptions p {
      font-size: 30px !important;
    }
  }
}

@media (orientation: portrait) {
  @media only screen and (max-width: 768px) {
    .header {
      height: 55px;
    }

    #headerOptions h1 {
      font-size: 20px;
    }
  }
}
