.projects {
  padding-top: 25px;
}

.projects h2 {
  font-size: 60px;
  text-align: center;
  background-color: black;
  color: white;
  display: inline-block;
  padding: 10px;
  /* margin-bottom: 50px; */
}
