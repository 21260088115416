.contact {
  background-color: black;
  color: white;
  padding: 25px 0px;
}

.contact h2 {
  text-align: center;
  font-size: 60px;
  margin-bottom: 35px;
}

form {
  max-width: 90%;
  width: 600px;
  margin: auto;
  margin-bottom: 35px;
}

#formName,
#formEmail,
#formSubject,
#formMessage {
  background-color: rgb(43, 43, 43);
  border: none;
  color: white;
}

input:focus,
input.form-control:focus,
#formMessage,
#sendButton {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#sendButton {
  background-color: black;
  border: 1px solid rgb(43, 43, 43);
  padding: 5px 55px;
}

#sendButton:hover {
  background-color: rgb(43, 43, 43);
}

.footerIcons {
  margin: 0px 5px;
}

.iconLinks {
  color: inherit;
  padding: 0 !important;
}

.iconLinks:hover {
  color: inherit;
}
