* {
  margin: 0;
  padding: 0;
}

.app {
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 40px !important;
  }
}
