.projectCarousels {
  padding-top: 80px;
}

.projectCarouselsImgContainer {
  position: relative;
  width: 1165px;
  margin: auto;
}

.deviceImages .arrows {
  position: absolute;
  bottom: 150px;
  cursor: pointer;
}

.deviceImages .arrows:first-of-type {
  left: 60px;
}

.deviceImages .arrows:last-of-type {
  right: 63px;
}

.deviceImages img:first-of-type {
  height: 290px;
  z-index: 1;
}

.deviceImages img:nth-of-type(2) {
  height: 350px;
}

.deviceImages img:last-of-type {
  height: 260px;
  z-index: 1;
}

.tabletCarousel {
  height: 233px;
  width: 175px;
  position: absolute;
  left: 138px;
  bottom: 45px;
  background-color: black;
  z-index: 1;
}

.notebookCarousel {
  height: 272px;
  width: 435px;
  position: absolute;
  left: 399px;
  bottom: 57px;
  background-color: black;
}

.phoneCarousel {
  height: 198px;
  width: 109px;
  position: absolute;
  left: 919px;
  bottom: 48px;
  background-color: black;
  z-index: 1;
}

.tabletProjectImages {
  height: 233px;
  width: 175px;
}

.phoneProjectImages {
  height: 198px;
  width: 109px;
}

.notebookProjectImages {
  height: 272px;
  width: 435px;
}

@media only screen and (max-width: 1199px) {
  .projectCarousels {
    padding-top: 90px;
  }

  .projectCarouselsImgContainer {
    width: 890px;
  }

  .tabletCarousel {
    height: 200px;
    width: 151px;
    left: 61px;
    bottom: 41px;
  }

  .notebookCarousel {
    height: 234px;
    width: 374px;
    left: 286px;
    bottom: 49px;
  }

  .phoneCarousel {
    height: 173px;
    width: 98px;
    left: 732px;
    bottom: 44px;
  }

  .tabletProjectImages {
    height: 200px;
    width: 151px;
  }

  .phoneProjectImages {
    height: 173px;
    width: 98px;
  }

  .notebookProjectImages {
    height: 234px;
    width: 374px;
  }

  .deviceImages img:first-of-type {
    height: 250px;
  }

  .deviceImages img:nth-of-type(2) {
    height: 300px;
  }

  .deviceImages img:last-of-type {
    height: 230px;
  }

  .deviceImages .arrows {
    bottom: 120px;
  }

  .deviceImages .arrows:first-of-type {
    left: 0px;
  }

  .deviceImages .arrows:last-of-type {
    right: 3px;
  }
}

@media only screen and (max-width: 991px) {
  .projectCarousels {
    padding-top: 80px;
  }

  .projectCarouselsImgContainer {
    width: 690px;
  }

  .tabletCarousel {
    height: 155px;
    width: 115px;
    left: 54px;
    bottom: 35px;
  }

  .notebookCarousel {
    height: 180px;
    width: 286px;
    left: 227px;
    bottom: 37px;
  }

  .phoneCarousel {
    height: 119px;
    width: 66px;
    left: 570px;
    bottom: 36px;
  }

  .tabletProjectImages {
    height: 155px;
    width: 115px;
  }

  .phoneProjectImages {
    height: 119px;
    width: 66px;
  }

  .notebookProjectImages {
    height: 180px;
    width: 286px;
  }

  .deviceImages img:first-of-type {
    height: 190px;
  }

  .deviceImages img:nth-of-type(2) {
    height: 230px;
  }

  .deviceImages img:last-of-type {
    height: 160px;
  }

  .deviceImages .arrows {
    bottom: 90px;
  }

  .deviceImages .arrows:first-of-type {
    left: 0px;
  }

  .deviceImages .arrows:last-of-type {
    right: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .projectCarouselsImgContainer {
    width: 515px;
  }

  .tabletCarousel {
    height: 114px;
    width: 84px;
    left: 33px;
    bottom: 30px;
  }

  .notebookCarousel {
    height: 140px;
    width: 224px;
    left: 163px;
    bottom: 29px;
  }

  .phoneCarousel {
    height: 90px;
    width: 50px;
    left: 432px;
    bottom: 30px;
  }

  .tabletProjectImages {
    height: 114px;
    width: 84px;
  }

  .phoneProjectImages {
    height: 90px;
    width: 50px;
  }

  .notebookProjectImages {
    height: 140px;
    width: 224px;
  }

  .deviceImages img:first-of-type {
    height: 140px;
  }

  .deviceImages img:nth-of-type(2) {
    height: 180px;
  }

  .deviceImages img:last-of-type {
    height: 120px;
  }

  .deviceImages .arrows {
    bottom: 70px;
  }

  .deviceImages .arrows:first-of-type {
    left: -15px;
  }

  .deviceImages .arrows:last-of-type {
    right: -12px;
  }
}

@media only screen and (max-width: 575px) {
  .projectCarouselsImgContainer {
    width: 290px;
  }

  .tabletCarousel {
    height: 114px;
    width: 84px;
    left: 0px;
    bottom: 10px;
  }

  .notebookCarousel {
    height: 141px;
    width: 224px;
    left: 33px;
    bottom: 29px;
  }

  .phoneCarousel {
    height: 90px;
    width: 50px;
    left: 241px;
    bottom: 10px;
  }

  .tabletProjectImages {
    height: 114px;
    width: 84px;
  }

  .phoneProjectImages {
    height: 90px;
    width: 50px;
  }

  .notebookProjectImages {
    height: 141px;
    width: 224px;
  }

  .deviceImages img:first-of-type {
    position: absolute;
    left: -5px;
    bottom: -20px;
    height: 140px;
  }

  .deviceImages img:nth-of-type(2) {
    height: 180px;
  }

  .deviceImages img:last-of-type {
    position: absolute;
    height: 120px;
    right: -5px;
    bottom: -20px;
  }

  .deviceImages .arrows {
    bottom: -40px;
  }

  .deviceImages .arrows:first-of-type {
    left: -10px;
  }

  .deviceImages .arrows:last-of-type {
    right: -9px;
  }

  .projectCarousels {
    padding-top: 30px;
  }
}
